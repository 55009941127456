'use client'

import dynamic from 'next/dynamic'
import { List, Collapse } from '@mui/material'

import {
	ExpandMoreTwoTone as ExpandMoreTwoToneIcon,
	ExpandLessTwoTone as ExpandLessTwoToneIcon,
	ListAltTwoTone as ListAltTwoToneIcon,
	DisplaySettingsTwoTone as DisplaySettingsTwoToneIcon,
	WorkspacesTwoTone as WorkspacesTwoToneIcon,
	SourceTwoTone as SourceTwoToneIcon,
	CategoryTwoTone as CategoryTwoToneIcon,
	PermMediaTwoTone as PermMediaTwoToneIcon,
	CommentTwoTone as CommentTwoToneIcon,
	PersonTwoTone as PersonTwoToneIcon,
	KeyTwoTone as KeyTwoToneIcon,
	MessageTwoTone as MessageTwoToneIcon,
	SettingsTwoTone as SettingsTwoToneIcon,
	PendingActionsTwoTone as PendingActionsTwoToneIcon,
	TokenTwoTone as TokenTwoToneIcon,
	GroupsTwoTone as GroupsTwoToneIcon,
	TuneTwoTone as TuneTwoToneIcon,
	CircleNotificationsTwoTone as CircleNotificationsTwoToneIcon,
	ViewModuleTwoTone as ViewModuleTwoToneIcon
} from '@mui/icons-material'

// Types
import type { FC } from 'react'
import type { MenuProps } from '@/types/components/molecules/menu'

// Helpers
import { useSettingsStore } from '@/helpers/store/settings'
import { useUserStore } from '@/helpers/store/user'

// Utils
import { checkAccess } from '@/utils/check-access'

// Components
const ListItemAtom = dynamic(() => import('@/components/atoms/items/list'))

// Import all components
// @ts-expect-error: Should expect context
const modules = require.context('./items', false, /\.tsx$/)
const menuModules: Record<string, FC<MenuProps>> = {}

modules.keys().forEach((key: string) => {
	const moduleName = key.replace('./', '').replace('.tsx', '')
	menuModules[moduleName] = modules(key).default
})

const MenuMolecule = (props: MenuProps) => {
	// Props
	const { lng } = props

	// Variables
	const { menuOpen, menuOpenItems, setMenuOpen, setMenuOpenItems } = useSettingsStore()
	const { group } = useUserStore()

	return (
		<>
			{Object.keys(menuModules).map(moduleName => {
				const MenuComponent = menuModules[moduleName]
				return <MenuComponent key={moduleName} lng={lng} />
			})}

			{(checkAccess(group?.access_value, 'menu-group', 'read') || checkAccess(group?.access_value, 'menu', 'read')) && (
				<>
					<ListItemAtom
						lng={lng}
						href="#"
						text="common:link.menus"
						icon={<ListAltTwoToneIcon />}
						endIcon={menuOpenItems.menus ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
						onlyIcon={!menuOpen}
						handleClick={() => setMenuOpenItems({ ...menuOpenItems, ...{ menus: !menuOpenItems.menus } })}
					/>

					<Collapse in={menuOpenItems.menus} timeout="auto" unmountOnExit sx={{ ...(!menuOpen && { display: { md: 'none' } }) }}>
						<List component="div" disablePadding>
							{checkAccess(group?.access_value, 'menu', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/menu`}
									text="common:link.manage"
									icon={<DisplaySettingsTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}

							{checkAccess(group?.access_value, 'menu-group', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/menu-group`}
									text="common:link.menuGroups"
									icon={<WorkspacesTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}
						</List>
					</Collapse>
				</>
			)}

			{(checkAccess(group?.access_value, 'category', 'read') || checkAccess(group?.access_value, 'article', 'read')) && (
				<>
					<ListItemAtom
						lng={lng}
						href="#"
						text="common:link.articles"
						icon={<SourceTwoToneIcon />}
						endIcon={menuOpenItems.articles ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
						onlyIcon={!menuOpen}
						handleClick={() => setMenuOpenItems({ ...menuOpenItems, ...{ articles: !menuOpenItems.articles } })}
					/>

					<Collapse in={menuOpenItems.articles} timeout="auto" unmountOnExit sx={{ ...(!menuOpen && { display: { md: 'none' } }) }}>
						<List component="div" disablePadding>
							{checkAccess(group?.access_value, 'article', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/article`}
									text="common:link.manage"
									icon={<DisplaySettingsTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}

							{checkAccess(group?.access_value, 'category', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/category`}
									text="common:link.categories"
									icon={<CategoryTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}
						</List>
					</Collapse>
				</>
			)}

			{(checkAccess(group?.access_value, 'field-category', 'read') || checkAccess(group?.access_value, 'field', 'read')) && (
				<>
					<ListItemAtom
						lng={lng}
						href="#"
						text="common:link.fields"
						icon={<TuneTwoToneIcon />}
						endIcon={menuOpenItems.fields ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
						onlyIcon={!menuOpen}
						handleClick={() => setMenuOpenItems({ ...menuOpenItems, ...{ fields: !menuOpenItems.fields } })}
					/>

					<Collapse in={menuOpenItems.fields} timeout="auto" unmountOnExit sx={{ ...(!menuOpen && { display: { md: 'none' } }) }}>
						<List component="div" disablePadding>
							{checkAccess(group?.access_value, 'field', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/field`}
									text="common:link.manage"
									icon={<DisplaySettingsTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}

							{checkAccess(group?.access_value, 'field-category', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/field-category`}
									text="common:link.categories"
									icon={<CategoryTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}
						</List>
					</Collapse>
				</>
			)}

			{checkAccess(group?.access_value, 'media', 'read') && (
				<ListItemAtom
					lng={lng}
					href={`/${lng}/media`}
					text="common:link.media"
					icon={<PermMediaTwoToneIcon />}
					onlyIcon={!menuOpen}
					handleClick={() => {
						setMenuOpen(true)
					}}
				/>
			)}

			{(checkAccess(group?.access_value, 'module', 'read') || checkAccess(group?.access_value, 'module-category', 'read')) && (
				<>
					<ListItemAtom
						lng={lng}
						href="#"
						text="common:link.modules"
						icon={<ViewModuleTwoToneIcon />}
						endIcon={menuOpenItems.moduels ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
						onlyIcon={!menuOpen}
						handleClick={() => setMenuOpenItems({ ...menuOpenItems, ...{ moduels: !menuOpenItems.moduels } })}
					/>

					<Collapse in={menuOpenItems.moduels} timeout="auto" unmountOnExit sx={{ ...(!menuOpen && { display: { md: 'none' } }) }}>
						<List component="div" disablePadding>
							{checkAccess(group?.access_value, 'module', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/module`}
									text="common:link.manage"
									icon={<DisplaySettingsTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}

							{checkAccess(group?.access_value, 'module', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/module-category`}
									text="common:link.categories"
									icon={<CategoryTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}
						</List>
					</Collapse>
				</>
			)}

			{(checkAccess(group?.access_value, 'user', 'read') ||
				checkAccess(group?.access_value, 'access', 'read') ||
				checkAccess(group?.access_value, 'user-group', 'read') ||
				checkAccess(group?.access_value, 'sms', 'read') ||
				checkAccess(group?.access_value, 'session', 'read')) && (
				<>
					<ListItemAtom
						lng={lng}
						href="#"
						text="common:link.users"
						icon={<PersonTwoToneIcon />}
						endIcon={menuOpenItems.users ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
						onlyIcon={!menuOpen}
						handleClick={() => setMenuOpenItems({ ...menuOpenItems, ...{ users: !menuOpenItems.users } })}
					/>

					<Collapse in={menuOpenItems.users} timeout="auto" unmountOnExit sx={{ ...(!menuOpen && { display: { md: 'none' } }) }}>
						<List component="div" disablePadding>
							{checkAccess(group?.access_value, 'user', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/user`}
									text="common:link.manage"
									icon={<DisplaySettingsTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}

							{checkAccess(group?.access_value, 'user-group', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/user-group`}
									text="common:link.userGroups"
									icon={<GroupsTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}

							{checkAccess(group?.access_value, 'comment', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/comment`}
									text="common:link.comments"
									icon={<CommentTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}

							{checkAccess(group?.access_value, 'token', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/token`}
									text="common:link.tokens"
									icon={<TokenTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}

							{checkAccess(group?.access_value, 'setting', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/setting`}
									text="common:link.settings"
									level={1}
									icon={<SettingsTwoToneIcon />}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}

							{checkAccess(group?.access_value, 'log', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/log`}
									text="common:link.logs"
									icon={<PendingActionsTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}

							{checkAccess(group?.access_value, 'access', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/access`}
									text="common:link.accesses"
									icon={<KeyTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}
						</List>
					</Collapse>
				</>
			)}

			{checkAccess(group?.access_value, 'sms', 'read') && (
				<>
					<ListItemAtom
						lng={lng}
						href="#"
						text="common:link.notifications"
						icon={<CircleNotificationsTwoToneIcon />}
						endIcon={menuOpenItems.notifications ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
						onlyIcon={!menuOpen}
						handleClick={() => setMenuOpenItems({ ...menuOpenItems, ...{ notifications: !menuOpenItems.notifications } })}
					/>

					<Collapse in={menuOpenItems.notifications} timeout="auto" unmountOnExit sx={{ ...(!menuOpen && { display: { md: 'none' } }) }}>
						<List component="div" disablePadding>
							{checkAccess(group?.access_value, 'sms', 'read') && (
								<ListItemAtom
									lng={lng}
									href={`/${lng}/search/sms`}
									text="common:link.sms"
									icon={<MessageTwoToneIcon />}
									level={1}
									onlyIcon={!menuOpen}
									handleClick={() => setMenuOpen(true)}
								/>
							)}
						</List>
					</Collapse>
				</>
			)}
		</>
	)
}

export default MenuMolecule
